<template>
  <div>
		<b-row>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="ApartmentIcon"
					color="success"
          :statistic="itemsCard.corporate_active + itemsCard.corporate_inactive"
          statistic-title="Total Corporate"
        />
      </b-col>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="ApartmentIcon"
					color="success"
          :statistic="itemsCard.corporate_active"
          statistic-title="Corporate Active"
        />
      </b-col>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="ApartmentIcon"
					color="danger"
          :statistic="itemsCard.corporate_inactive"
          statistic-title="Corporate Inactive"
        />
      </b-col>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="ApartmentIcon"
					color="warning"
          :statistic="itemsCard.corporate_exp"
          statistic-title="Expiry Soon"
        />
      </b-col>
			<b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="ApartmentIcon"
					color="warning"
          :statistic="itemsCard.corporate_limit"
          statistic-title="Hit Limit"
        />
      </b-col>
		</b-row>
		<b-button
			v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			variant="primary"
			class="mb-2"
			@click="gotoAddCorpoate()"
		>
	  	Add Corporate
		</b-button>

		<b-overlay
			:show="overlayUserCorporate"
			spinner-variant="primary"
			spinner-type="grow"
			spinner-small
			rounded="sm"
		>
			<b-card-code
				title="Data Corporate"
				no-body
				:aria-hidden="overlayUserCorporate ? true : null"
			>
				<b-card-body>
					<div class="d-flex justify-content-between flex-wrap">
						<!-- sorting  -->
						<b-form-group
							label="Sort"
							label-size="sm"
							label-align-sm="left"
							label-cols-sm="3"
							label-for="sortBySelect"
							class="mr-1 mb-md-0"
						>
							<b-input-group size="sm">
								<b-form-select
									id="sortBySelect"
									v-model="sortBy"
									:options="sortOptions"
								>
									<template #first>
										<option value="">
											none
										</option>
									</template>
								</b-form-select>
								<b-form-select
									v-model="sortDesc"
									size="sm"
									:disabled="!sortBy"
								>
									<option :value="false">
										ASC
									</option>
									<option :value="true">
										DESC
									</option>
								</b-form-select>
							</b-input-group>
						</b-form-group>

						<!-- filter -->
						<b-form-group
							label="Filter"
							label-cols-sm="2"
							label-align-sm="left"
							label-size="sm"
							label-for="filterInput"
							class="mb-0"
						>
							<b-input-group size="sm">
								<b-form-input
									id="filterInput"
									v-model="filter"
									type="search"
									placeholder="Type to Search"
								/>
								<b-input-group-append>
									<b-button
										:disabled="!filter"
										@click="filter = ''"
									>
										Clear
									</b-button>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</div>
				</b-card-body>

				<b-table
					striped
					hover
					responsive
					class="position-relative"
					:per-page="perPage"
					:current-page="currentPage"
					:items="items"
					:fields="fields"
					:sort-by.sync="sortBy"
					:sort-desc.sync="sortDesc"
					:sort-direction="sortDirection"
					:filter="filter"
					:filter-included-fields="filterOn"
					@filtered="onFiltered"
				>
					<template #cell(name)="data">
						<div class="d-flex flex-row">
							<b-avatar v-if="data.item.logo !== null"
								class="mr-50"
								:src="`https://api.the-netwerk.com/public/storage/corporate/logo/${data.item.logo}`"
							/> 
							<b-avatar v-else
								class="mr-50"
								variant="primary"
								:text="data.item.name | initial"
							/>
							<div class="d-flex flex-column">
								<p class="m-0" v-b-tooltip.hover.bottom="data.item.name">
									{{ data.item.name }}
								</p>
								<small class="text-muted">
									Exp: {{ data.item.expired_date ? data.item.expired_date : '-' }}
								</small>
							</div>
						</div>
					</template>

					<template #cell(total_member)="data">
						<b-progress
							v-b-tooltip.hover.top="data.item.member_text"
							:max="data.item.target_member"
							class="progress-bar-info"
						>
							<b-progress-bar
								:value="data.item.total_member"
								variant="info"
							/>
						</b-progress>
					</template>

					<template #cell(actions)="data">
						<span>
							<b-dropdown
								variant="link"
								toggle-class="text-decoration-none"
								no-caret
							>
								<template v-slot:button-content>
									<feather-icon
										icon="SettingsIcon"
										size="16"
										class="text-body align-middle mr-25"
									/>
								</template>
								<b-dropdown-item @click="gotoDetail(data.item.id)">
									<feather-icon
										icon="ListIcon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Detail</span>
								</b-dropdown-item>
								<b-dropdown-item @click="gotoEdit(data.item.id)">
									<feather-icon
										icon="Edit2Icon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Edit</span>
								</b-dropdown-item>
								<b-dropdown-item @click="gotoDelete(data.item.id)">
									<feather-icon
										icon="TrashIcon"
										class="mr-50"
										style="margin-top: -5px"
									/>
									<span>Delete</span>
								</b-dropdown-item>
							</b-dropdown>
						</span>
					</template>
				</b-table>

				<b-card-body class="d-flex justify-content-between flex-wrap pt-0">
					<!-- page length -->
					<b-form-group
						label="Per Page"
						label-cols="6"
						label-align="left"
						label-size="sm"
						label-for="sortBySelect"
						class="text-nowrap mb-md-0 mr-1"
					>
						<b-form-select
							id="perPageSelect"
							v-model="perPage"
							size="sm"
							inline
							:options="pageOptions"
						/>
					</b-form-group>

					<!-- pagination -->
					<div>
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRows"
							:per-page="perPage"
							first-number
							last-number
							prev-class="prev-item"
							next-class="next-item"
							class="mb-0"
						>
							<template #prev-text>
								<feather-icon
									icon="ChevronLeftIcon"
									size="18"
								/>
							</template>
							<template #next-text>
								<feather-icon
									icon="ChevronRightIcon"
									size="18"
								/>
							</template>
						</b-pagination>
					</div>
				</b-card-body>
			</b-card-code>
	</b-overlay>
  </div>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
	import {
		BTable, BAvatar, BBadge, BCardBody, VBModal, VBTooltip,
		BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, 
		BDropdown, BDropdownItem, BOverlay,
		BProgress, BProgressBar, BCol, BRow
	} from 'bootstrap-vue'
  import Ripple from 'vue-ripple-directive'
	import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
  
  export default {
	components: {
		BCardCode,
		BTable,
		BAvatar,
		BBadge,
		BFormGroup,
		BFormSelect,
		BPagination,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
		BButton,
		BCardBody,
		BDropdown, BDropdownItem, BOverlay,
		BProgress, BProgressBar, BCol, BRow,
		StatisticCardHorizontal
	},
	directives: {
		Ripple,
		'b-tooltip': VBTooltip,
	},
	data() {
	  return {
			perPage: 50,
			pageOptions: [10, 50, 100],
			currentPage: 1,
			sortBy: '',
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,
			filterOn: [],
			infoModal: {
				id: 'info-modal',
				title: '',
				content: '',
			},
			fields: [
				{ key: 'id', label: 'ID' },
				{ key: 'name', label: 'Name', sortable: true },
				{ key: 'contact', label: 'Contact', sortable: true },
				{ key: 'phone', label: 'Phone', sortable: true },
				{ key: 'total_member', label: 'Member'},
				{ key: 'actions', label: 'Actions' }
			],
			overlayUserCorporate: true,
			active_role: null
	  }
	},
	computed: {
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter(f => f.sortable)
				.map(f => ({ text: f.label, value: f.key }))
		},
		items() {
			return this.$store.state.corporate.corporate
		},
		itemsCard() {
			return this.$store.state.corporate
		},
		totalRows() {
			return this.$store.state.corporate.corporate.length
		}
	},
	created() {
	  this.checkAuth()
	},
	mounted() {
	  this.active_role = localStorage.getItem('role')
		this.getDataCorporate()
	},
	filters: {
		initial: function (val) {
			var result = "";
			let tokens = val.split("")

			if(tokens.length == 1) {
				result = tokens[0].substring(0,1).toUpperCase()
			} else {
				for(let i=0; i<2; i++){
					result += tokens[i].substring(0,1).toUpperCase()
				}
			}

			return result
		}
	},
	methods: {
		info(item, index, button) {
			this.infoModal.title = `Row index: ${index}`
			this.infoModal.content = JSON.stringify(item, null, 2)
			this.$root.$emit('bv::show::modal', this.infoModal.id, button)
		},
		resetInfoModal() {
			this.infoModal.title = ''
			this.infoModal.content = ''
		},
		onFiltered(filteredItems) {
			// Trigger pagination to update the number of buttons/pages due to filtering
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
	  
	  async getDataCorporate() {
			this.$store.dispatch('corporate/loadCorporate')
		  	.then(res => {
					this.overlayUserCorporate = false
		  	})
				.catch(error => {})
		},

		gotoAddCorpoate() {
			this.$router.push({ path: 'corporate/add' })
		},

		gotoDetail(id) {
			this.$router.push({ path: `corporate/${id}` })
		},

		gotoEdit(id) {
			this.$router.push({ path: `corporate/edit/${id}` })
		},

		gotoDelete(id) {
			let cid = localStorage.getItem('client_id')
			
			this.$bvModal
				.msgBoxConfirm('Are you sure to delete this data?', {
					title: 'Delete confirmation',
					size: 'sm',
					okVariant: 'danger',
					okTitle: 'Delete',
					cancelTitle: 'Cancel',
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(value => {
					if(value) {
						this.$axios.delete(`${cid}/corporate/${id}`)
							.then(res => {
								// Success code
								this.$bvToast.toast('Success', {
									title: 'Delete data corporate',
									variant: 'success',
									solid: true,
									toaster: 'b-toaster-bottom-right',
								})
								
								this.items = []
								this.getDataCorporate()
						})
					}
			})
		}
	}
}
</script>